import React, { useEffect, useState } from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import { TListingClient } from 'types/TListing';

type TBiddingAppProps = {
  appState: {
    listing: {
      bidsInfo: TListingClient['bidsInfo'];
      itemId: TListingClient['itemId'];
      title: TListingClient['title'];
      seller: TListingClient['seller'];
      priceInfo: TListingClient['priceInfo'];
      isCarAd: TListingClient['isCarAd'];
      flags: TListingClient['flags'];
    };
  };
  environment: any;
};

let Bidding;

const BiddingApp = ({ appState, environment }: TBiddingAppProps) => {
  const {
    listing: {
      bidsInfo,
      itemId,
      title,
      seller: { name },
      priceInfo: { priceType },
      isCarAd,
    },
  } = appState;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (bidsInfo.isBiddingEnabled) {
      const loadComponent = async () => {
        try {
          Bidding = (await import(/* webpackChunkName: "bidding" */ './components/Bidding/Bidding')).default;
          setIsLoaded(true);
        } catch (err) {}
      };

      loadComponent();
    }
  }, [bidsInfo.isBiddingEnabled]);

  if (!isLoaded) {
    return null;
  }

  return (
    <EnvironmentProvider value={environment}>
      <Bidding
        bidsInfo={bidsInfo}
        itemId={itemId}
        title={title}
        sellerName={name}
        priceType={priceType}
        withPhoneNumber={isCarAd}
      />
    </EnvironmentProvider>
  );
};

export default BiddingApp;
