import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import SellerInfo from './components/SellerInfo/SellerInfo';
import SellerContactOptions from './components/SellerContactOptions/SellerContactOptions';
import { TListingClient, TListingSellerInfo } from 'types/TListing';
import { getHeroImageUrl } from '../../utils/getImageUrls';

type TSellerSidebarAppProps = {
  appState: {
    listing: {
      itemId: TListingClient['itemId'];
      title: TListingClient['title'];
      gallery: TListingClient['gallery'];
      priceInfo: TListingClient['priceInfo'];
      seller: TListingSellerInfo;
      flags: TListingClient['flags'];
      traits: TListingClient['traits'];
      isCarAd: TListingClient['isCarAd'];
      isAutomotiveAd: TListingClient['isAutomotiveAd'];
    };
  };
  environment: any;
};

const SellerSidebarApp = ({ appState, environment }: TSellerSidebarAppProps) => {
  const {
    listing: {
      itemId,
      title,
      gallery,
      priceInfo,
      seller,
      isCarAd,
      isAutomotiveAd,
      traits,
      flags: { isAdmarkt, hasCallTracking, isCarDealer, allowTradeInRequest, isInBinExcludedCategories },
    },
  } = appState;

  return (
    <EnvironmentProvider value={environment}>
      <SellerInfo seller={seller} isCarAd={isCarAd} />
      <SellerContactOptions
        itemId={itemId}
        title={title}
        adImageThumbnail={getHeroImageUrl(gallery.imageUrls[0])}
        priceInfo={priceInfo}
        seller={seller}
        isCarAd={isCarAd}
        isAutomotiveAd={isAutomotiveAd}
        isCarDealer={isCarDealer}
        allowTradeInRequest={allowTradeInRequest}
        isAdmarkt={isAdmarkt}
        hasCallTracking={hasCallTracking}
        isInBinExcludedCategories={isInBinExcludedCategories}
        traits={traits}
      />
    </EnvironmentProvider>
  );
};

export default SellerSidebarApp;
