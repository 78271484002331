import React, { useContext, useEffect, useState } from 'react';
import { ButtonIcon } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import Cookies from 'js-cookie';

import sendAsq from '../../repositories/sendAsq';

import { trackGAEvent } from '../../../../utils/gaTracking';
import { GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';
import { createShowAsqHash, sendSubmitAsqGAEvents } from './utils';
import { AsqHashParams } from './consts';
import { getHashParamValue, deleteAllHashParams } from '../../../../utils/hashParams';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import LoginDialogContainer from '../../../../components/LoginDialog/LoginDialogContainer';
import SellerContactOptionsButton from './SellerContactOptionsButton';
import { displayBannerAfterInteraction } from '../../../BannersApp/components/Banners/utils/banners';
import { BANNER_POSITION } from '../../../BannersApp/constants/banners';

import { TAsqPayload } from 'types/TAsq';
import { TListingClient } from 'types/TListing';
import { TAsqMessageType } from './types';

import Classes from './SellerContactOptions.scss';

let AsqDialog;

type TAsqProps = {
  itemId: TListingClient['itemId'];
  title: TListingClient['title'];
  sellerName: TListingClient['seller']['name'];
  sellerLocation: TListingClient['seller']['location'];
  adImageThumbnail: string;
  isCarDealer: boolean;
  allowTradeInRequest: boolean;
  isPrimary: boolean;
  isAutomotiveAd: boolean;
  sellerId: TListingClient['seller']['id'];
  showProfilePicture: TListingClient['seller']['showProfilePicture'];
  showSalesRepresentatives: TListingClient['seller']['showSalesRepresentatives'];
  financeAvailable: TListingClient['seller']['financeAvailable'];
  sellerType: TListingClient['seller']['sellerType'];
  priceInfo: TListingClient['priceInfo'];
  gaEvent?: {
    label: string;
  };
  traits: TListingClient['traits'];
};

const Asq = ({
  itemId,
  title,
  sellerName,
  sellerLocation,
  adImageThumbnail,
  isPrimary,
  isCarDealer,
  allowTradeInRequest,
  isAutomotiveAd,
  sellerId,
  showProfilePicture,
  showSalesRepresentatives,
  financeAvailable,
  sellerType,
  priceInfo,
  gaEvent,
  traits,
}: TAsqProps) => {
  const { t } = useI18nContext();
  const {
    userLoggedIn,
    userDetails: { name: userName = '' } = {},
    xsrfToken,
    hasLicensePlate,
  } = useContext(EnvironmentContext);

  const [asqType, setAsqType] = useState<TAsqMessageType>('');
  const [showAsqDialog, setShowAsqDialog] = useState(false);
  const [isAsqDialogLoaded, setAsqDialogLoaded] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);

  // used for blocking the confirm button on the modal
  const [isAsqRequestOngoing, setAsqRequestOngoing] = useState(false);

  const loadAsqDialog = async () => {
    if (isAsqDialogLoaded) {
      return;
    }

    try {
      if (isAutomotiveAd) {
        AsqDialog = (await import(/* webpackChunkName: "CarsAsqDialog" */ './AsqDialog/CarsAsqDialog/CarsAsqDialog'))
          .default;
      } else {
        AsqDialog = (
          await import(/* webpackChunkName: "GenericAsqDialog" */ './AsqDialog/GenericAsqDialog/GenericAsqDialog')
        ).default;
      }
      setAsqDialogLoaded(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to load AsqDialog', err);
    }
  };

  const showASQModal = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailModalShown);
    loadAsqDialog();
    setShowAsqDialog(true);
  };

  const handleAsqOpen = () => {
    if (!userLoggedIn) {
      setShowLoginDialog(true);
      return;
    }

    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailInitiated);
    showASQModal();
  };

  const handleMessageClick = () => {
    if (gaEvent) {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailBegin, gaEvent.label);
    } else {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailBegin);
    }
    setAsqType('');
    handleAsqOpen();
  };

  const submitAsq = async (asqPayload: TAsqPayload): Promise<void> => {
    const { message, buyerName, buyerPhoneNumber, location, vehicleTradeInRequest } = asqPayload;

    setAsqRequestOngoing(true);

    const [{ success }, { default: showToastNotification }] = await Promise.all([
      sendAsq({
        itemId,
        xsrfToken,
        message,
        buyerName,
        buyerPhoneNumber,
        location,
        vehicleTradeInRequest,
      }),
      import(/* webpackChunkName: "toastNotification" */ '../../../../components/Toast/ToastNotification'),
    ]);

    // success state
    if (success) {
      sendSubmitAsqGAEvents(asqPayload, sellerType, hasLicensePlate, gaEvent);

      setShowAsqDialog(false);
      showToastNotification({
        type: 'success',
        description: t('sellerContact.asq.notification.messageSent'),
      });

      // After ASQ is sent successfully, below event is fired
      // To show AdmarktSimilarItems at the top the page
      // This approach has been used to keep the modularity of React Apps intact
      // And this will help us to try Module Federation for ASQ
      const event = new Event('asqSent');
      window.dispatchEvent(event);
      displayBannerAfterInteraction(BANNER_POSITION.STATIC.ACTION);
    } else {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailFailed);
      // how to show message for specific errors when fetch Api doesnt fwd the error response
      showToastNotification({
        type: 'error',
        description: t('sellerContact.asq.notification.messageFailed'),
      });
    }

    setAsqRequestOngoing(false);
  };

  const handleAsqCancel = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailCancel);
    setShowAsqDialog(false);
  };

  const handleAsqConfirm = (asqPayload: TAsqPayload): void => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailAttempt);
    submitAsq(asqPayload);
  };

  const handleShowAsqHash = (): void => {
    if (typeof window === 'undefined') {
      return;
    }

    const actionType = getHashParamValue(AsqHashParams.SHOW_ASQ.KEY);

    if (actionType === AsqHashParams.SHOW_ASQ.VALUE) {
      const asqMessageType = (getHashParamValue(AsqHashParams.MESSAGE_TYPE.KEY) as TAsqMessageType) || '';
      const previousAction = getHashParamValue(AsqHashParams.PREVIOUS_ACTION.KEY) || '';
      trackGAEvent(
        GA_TRACK_EVENTS_ACTIONS.R2SEmailBeginFromURL,
        `${AsqHashParams.MESSAGE_TYPE.KEY}=${asqMessageType};${AsqHashParams.PREVIOUS_ACTION.KEY}=${previousAction}`,
      );
      setAsqType(asqMessageType);
      deleteAllHashParams();
      handleAsqOpen();
    }
  };

  useEffect(() => {
    handleShowAsqHash();

    window.addEventListener('hashchange', handleShowAsqHash, false);
    return () => {
      window.removeEventListener('hashchange', handleShowAsqHash, false);
    };

    // keep deps empty to run useEffect hook only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginSuccess = () => {
    Cookies.set('sendASQLoginReload', 'true');
    document.location.reload();
  };

  useEffect(() => {
    // if isAdOwner we remove the cookie before redirecting to SVIP in checkListingAvailabilityMiddleware.
    const sendASQFlag = Cookies.get('sendASQLoginReload');
    if (sendASQFlag) {
      Cookies.remove('sendASQLoginReload');
      document.location.hash = createShowAsqHash(asqType, AsqHashParams.PREVIOUS_ACTION.VALUES.LOGIN);
    }

    // keep deps empty to run useEffect hook only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SellerContactOptionsButton className={Classes.button} isPrimary={isPrimary} onClick={handleMessageClick}>
        <ButtonIcon name="messages" isStartIcon={false} />
        <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.asq.message" />
      </SellerContactOptionsButton>

      {showAsqDialog && isAsqDialogLoaded && (
        <AsqDialog
          listingTitle={title}
          sellerName={sellerName}
          userName={userName}
          isCarDealer={isCarDealer}
          allowTradeInRequest={allowTradeInRequest}
          traits={traits}
          onCancel={handleAsqCancel}
          onConfirm={handleAsqConfirm}
          sellerLocation={sellerLocation}
          sellerId={sellerId}
          adImageThumbnail={adImageThumbnail}
          showProfilePicture={showProfilePicture}
          showSalesRepresentatives={showSalesRepresentatives}
          financeAvailable={financeAvailable}
          isAsqRequestOngoing={isAsqRequestOngoing}
          withTestDrive={asqType === AsqHashParams.MESSAGE_TYPE.VALUES.TEST_DRIVE}
          priceInfo={priceInfo}
        />
      )}

      {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
    </>
  );
};

export default Asq;
