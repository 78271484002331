import fetchAPI from '../../../utils/fetchAPI';

import { TAsqClient, TAsqPayload } from 'types/TAsq';

type TParams = {
  itemId: string;
  xsrfToken: string;
  message: TAsqPayload['message'];
  buyerName?: TAsqPayload['buyerName'];
  buyerPhoneNumber?: TAsqPayload['buyerPhoneNumber'];
  location?: TAsqPayload['location'];
  vehicleTradeInRequest?: TAsqPayload['vehicleTradeInRequest'];
};

export default async function sendAsq({
  itemId,
  xsrfToken,
  message,
  buyerName,
  buyerPhoneNumber,
  location,
  vehicleTradeInRequest,
}: TParams): Promise<TAsqClient> {
  try {
    return await fetchAPI({
      url: '/v/api/asq',
      method: 'POST',
      xsrfToken,
      payload: {
        itemId,
        message,
        buyerName,
        buyerPhoneNumber,
        location,
        vehicleTradeInRequest,
      },
    });
  } catch (e) {
    return {};
  }
}
