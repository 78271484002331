import { TAsqPayload } from 'types/TAsq';

export const GaFields: Partial<Record<keyof TAsqPayload, string>> = {
  buyerName: 'NAAM',
  location: 'WOONPLAATS',
  buyerPhoneNumber: 'TELEFOONNUMMER',
  vehicleTradeInRequest: 'INRUILVOORSTEL',
};

export const AsqHashParams = {
  SHOW_ASQ: {
    KEY: 'action',
    VALUE: 'showAsq',
  },
  MESSAGE_TYPE: {
    KEY: 'asqMessageType',
    VALUES: {
      TEST_DRIVE: 'testDrive',
    },
  },
  PREVIOUS_ACTION: {
    KEY: 'previousAction',
    VALUES: {
      LOGIN: 'login',
      TEST_DRIVE: 'testDrive',
    },
  },
};

export const testDriveSchedulerDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const testDriveSchedulerTimeSlots = [
  { start: 9, end: 12 },
  { start: 12, end: 15 },
  { start: 15, end: 18 },
];
