import React, { useContext, useEffect, useState } from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Link, Icon } from '@hz-design-system/web-ui';

import { TListingClient, TListingSellerInfo } from 'types/TListing';
import { TSellerResponseRateClient } from 'types/TSellerResponseRate';
import { TSellerProfileClient } from 'types/TSellerProfile';
import getSellerProfile from '../../repositories/getSellerProfile';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import calculateActiveSinceDiff from './utils/calculateActiveSinceDiff';

import SellerReviews from './SellerReviews';
import SellerExternalReviews from './SellerExternalReviews';
import SellerIdentity from './SellerIdentity';
import SellerIdentityIcon from './SellerIdentityIcon';
import SellerLocation from './SellerLocation';
import SellerWebsiteLink from './SellerWebsiteLink';
import SellerTestDriveLink from './SellerTestDriveLink';
import SellerTrustIndicators from './SellerTrustIndicators';
import SellerFollowButton from './SellerFollowButton';
import SellerResponseRate from './SellerResponseRate';
import SellerProfilePictures from './SellerProfilePictures';
import SellerSalesRepresentatives from './SellerSalesRepresentatives';

import Classes from './SellerInfo.scss';
import getSellerResponseRateFromClient from '../../repositories/getSellerResponseRateFromClient';
import { PILOT_DEALERS } from '../../../../utils/constants';

type TSellerInfoProps = {
  seller: TListingSellerInfo;
  isCarAd: TListingClient['isCarAd'];
};

const SellerInfo = ({ seller, isCarAd }: TSellerInfoProps) => {
  const { t } = useI18nContext();
  const {
    isBot,
    links: { seller: sellerLinks = {} } = {},
    userDetails: { isAdOwner = false } = {},
    hasLicensePlate,
    isVerifiedRefurbishedAd,
  } = useContext(EnvironmentContext);
  const {
    id,
    name,
    activeSince,
    location,
    showMap,
    pageUrl,
    isSaved,
    sellerType,
    sellerWebsiteDisplayUrl,
    sellerWebsiteUrl,
    showProfilePicture,
    showSalesRepresentatives,
    showSellerResponseRate,
    requestExternalReviews,
    allowCarTestDriveRequest,
  } = seller;
  const [profile, setProfile] = useState<TSellerProfileClient>({});
  const [sellerResponseRate, setSellerResponseRate] = useState<TSellerResponseRateClient['responseData']>();
  const activeSinceDiff = calculateActiveSinceDiff(activeSince, t);

  useEffect(() => {
    (async () => {
      setProfile(await getSellerProfile({ sellerId: id, requestExternalReviews }));
    })();
  }, [id, requestExternalReviews]);

  useEffect(() => {
    const makeRequest = async () => {
      const response = await getSellerResponseRateFromClient({ sellerId: id });
      setSellerResponseRate(response.responseData);
    };

    makeRequest();
  }, []);

  // SMB verified does not show for cars, as automotive is not included in the SMB bundles
  // refer to BNL-7414
  const shouldShowSmbVerified = !isCarAd && Boolean(profile.smbVerified);

  return (
    <div className={`block-wrapper-s ${Classes.root}`}>
      <SellerProfilePictures
        showProfilePicture={showProfilePicture}
        profilePictures={profile.profilePictures}
        name={name}
      />
      <div className={Classes.section}>
        <div className={Classes.row}>
          {!isAdOwner && <SellerFollowButton sellerId={id} isSaved={isSaved} />}
          <span className={Classes.name}>
            <SellerIdentityIcon sellerType={sellerType} />
            <Link href={pageUrl}>{name}</Link>
            {shouldShowSmbVerified && (
              <Icon
                name="checkmark-circle"
                color="signal-success-default"
                size="m"
                className={Classes.icon}
                title={t('sellerInfo.verifiedTooltip')}
              />
            )}
          </span>
        </div>
        <Trans className={Classes.row} i18nKey="sellerInfo.activeOnPlatform" activeSinceDiff={activeSinceDiff} />
        <div className={Classes.row}>
          <Link href={pageUrl}>{t('sellerInfo.seeAllItems')}</Link>
        </div>
        {shouldShowSmbVerified && (
          <div className={Classes.row}>
            <Link href={sellerLinks.smbProfile}>{t('sellerInfo.smbProfile')}</Link>
          </div>
        )}
        {!profile.externalSource && (
          <SellerReviews numberOfReviews={profile.numberOfReviews} averageScore={profile.averageScore} />
        )}
        {!isBot && <SellerIdentity sellerType={sellerType} />}
        {profile.externalSource && (
          <SellerExternalReviews
            sellerName={name}
            numberOfReviews={profile.numberOfReviews}
            averageScore={profile.averageScore}
            externalReviews={profile.externalReviews}
            provider={profile.provider}
            showProfilePicture={showProfilePicture}
            profilePictures={profile.profilePictures}
          />
        )}
      </div>
      <SellerTrustIndicators verifications={profile} shouldShowSmbVerified={shouldShowSmbVerified} />
      <div className={Classes.section}>
        <SellerLocation location={location} showMap={showMap} />
        <SellerWebsiteLink
          text={sellerWebsiteDisplayUrl}
          url={sellerWebsiteUrl}
          sellerType={sellerType}
          hasLicensePlate={hasLicensePlate}
        />
        {allowCarTestDriveRequest && !PILOT_DEALERS.includes(id) && <SellerTestDriveLink />}
      </div>
      {showSellerResponseRate && sellerResponseRate && Boolean(sellerResponseRate.rate) && !isVerifiedRefurbishedAd && (
        <SellerResponseRate label={sellerResponseRate.label} />
      )}
      {showSalesRepresentatives && <SellerSalesRepresentatives salesRepresentatives={profile.salesRepresentatives} />}
    </div>
  );
};

export default SellerInfo;
