import fetchAPI from '../../../utils/fetchAPI';

type TParams = {
  itemId: string;
  xsrfToken: string;
};

export default function registerPhoneClick({ itemId, xsrfToken }: TParams): void {
  try {
    fetchAPI({
      url: '/v/api/register-phone-click',
      method: 'POST',
      xsrfToken,
      payload: { itemId },
    });
  } catch (e) {}
}
